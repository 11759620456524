import Bandas from '../img/bandas_y_mangueras/BANDAS-INDUSTRIALES.jpg'
import Empaques from '../img/empaques/EMPAQUE-AUTOADHERIBLE.jpg'
import Pesado from '../img/equipo_pesado/BUJES-Y-TIRANTES.jpg'
import Pasillo from '../img/hules-pasillos/GARLOCK.jpg'
import Industrial from '../img/hules_industriales/SBR.jpg'

const stats = [
  { label: 'Contamos con 11 sucursales en la ciudad', value: '11 Mundos' },
  { label: 'Contamos con más de 7000 productos', value: '7000 productos' },
  { label: 'Más de 40 años en el mercado', value: '+40 años' },
]
const values = [
  {
    name: 'SOPORTES DE MOTOR Y TRANSMISIÓN',
    description: 'Todas las marcas de autos y camión',
  },
  {
    name: 'BANDAS Y MANGUERAS',
    description: 'Automotrices – Camión – Industriales – Especiales',
  },
  {
    name: 'GOMAS AUTOMOTRICES E INDUSTRIALES',
    description: 'Bujes –Tirantes – Machetas –Mofle y rebote',
  },
  {
    name: 'EMPAQUES',
    description: 'Puerta y cajuela – Planos – Industriales – Autoadheribles – Especiales',
  },
  {
    name: 'HULES INDUSTRIALES',
    description: 'Antifatiga – Neoprenos – SBR – Dieléctrico – Nitrilos – Siliconas – Hawaiana',
  },
  {
    name: 'HULES PASILLOS',
    description: 'Tramados – Linoleums – Sspaguetti – Rugosos – Cubre alfombras – Gimnasio',
  },
  {
    name: 'COMPLEMENTOS INDUSTRIALES',
    description: 'Botas industriales – Recubrimiento texturizado – Pegamentos industriales',
  },
]

export default function About() {
  return (
    <main className='isolate'>
      {/* Hero section */}
      <div className='relative isolate -z-10'>
        <svg
          className='absolute inset-x-0 top-0 -z-10 h-[64rem] w-full stroke-gray-200 [mask-image:radial-gradient(32rem_32rem_at_center,white,transparent)]'
          aria-hidden='true'
        >
          <defs>
            <pattern
              id='1f932ae7-37de-4c0a-a8b0-a6e3b4d44b84'
              width={200}
              height={200}
              x='50%'
              y={-1}
              patternUnits='userSpaceOnUse'
            >
              <path d='M.5 200V.5H200' fill='none' />
            </pattern>
          </defs>
          <svg x='50%' y={-1} className='overflow-visible fill-gray-50'>
            <path
              d='M-200 0h201v201h-201Z M600 0h201v201h-201Z M-400 600h201v201h-201Z M200 800h201v201h-201Z'
              strokeWidth={0}
            />
          </svg>
          <rect
            width='100%'
            height='100%'
            strokeWidth={0}
            fill='url(#1f932ae7-37de-4c0a-a8b0-a6e3b4d44b84)'
          />
        </svg>
        <div
          className='absolute left-1/2 right-0 top-0 -z-10 -ml-24 transform-gpu overflow-hidden blur-3xl lg:ml-24 xl:ml-48'
          aria-hidden='true'
        >
          <div
            className='aspect-[801/1036] w-[50.0625rem] bg-gradient-to-tr from-[#38bdf8] to-[#075985] opacity-30'
            style={{
              clipPath:
                'polygon(63.1% 29.5%, 100% 17.1%, 76.6% 3%, 48.4% 0%, 44.6% 4.7%, 54.5% 25.3%, 59.8% 49%, 55.2% 57.8%, 44.4% 57.2%, 27.8% 47.9%, 35.1% 81.5%, 0% 97.7%, 39.2% 100%, 35.2% 81.4%, 97.2% 52.8%, 63.1% 29.5%)',
            }}
          />
        </div>
        <div className='overflow-hidden'>
          <div className='mx-auto max-w-7xl px-6 pb-32 pt-36 sm:pt-60 lg:px-8 lg:pt-32'>
            <div className='mx-auto max-w-2xl gap-x-14 lg:mx-0 lg:flex lg:max-w-none lg:items-center'>
              <div className='w-full max-w-xl lg:shrink-0 xl:max-w-2xl'>
                <h1 className='text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl'>
                  Mundo de Hules
                </h1>
                <p className='relative mt-6 text-lg leading-8 text-gray-600 sm:max-w-md lg:max-w-none'>
                  Establecida en 1981, actualmente Mundo de Hules es una empresa líder en Jalisco,
                  especializada en la distribución y comercialización de todo tipo de hules
                  automotrices e industriales, brindando un servicio rápido, cordial y respetuoso a
                  particulares, talleres mecánicos e industrias, ofreciendo una amplia gama de
                  productos de hule, una mejora continua y un excelente servicio gracias al apoyo de
                  nuestro equipo de trabajo.
                </p>
              </div>
              <div className='mt-14 flex justify-end gap-8 sm:-mt-44 sm:justify-start sm:pl-20 lg:mt-0 lg:pl-0'>
                <div className='ml-auto w-44 flex-none space-y-8 pt-32 sm:ml-0 sm:pt-80 lg:order-last lg:pt-36 xl:order-none xl:pt-80'>
                  <div className='relative'>
                    <img
                      src={Bandas}
                      alt=''
                      className='aspect-[2/3] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg'
                    />
                    <div className='pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-gray-900/10' />
                  </div>
                </div>
                <div className='mr-auto w-44 flex-none space-y-8 sm:mr-0 sm:pt-52 lg:pt-36'>
                  <div className='relative'>
                    <img
                      src={Empaques}
                      alt=''
                      className='aspect-[2/3] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg'
                    />
                    <div className='pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-gray-900/10' />
                  </div>
                  <div className='relative'>
                    <img
                      src={Pesado}
                      alt=''
                      className='aspect-[2/3] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg'
                    />
                    <div className='pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-gray-900/10' />
                  </div>
                </div>
                <div className='w-44 flex-none space-y-8 pt-32 sm:pt-0'>
                  <div className='relative'>
                    <img
                      src={Pasillo}
                      alt=''
                      className='aspect-[2/3] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg'
                    />
                    <div className='pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-gray-900/10' />
                  </div>
                  <div className='relative'>
                    <img
                      src={Industrial}
                      alt=''
                      className='aspect-[2/3] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg'
                    />
                    <div className='pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-gray-900/10' />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Content section */}
      <div className='mx-auto -mt-12 max-w-7xl px-6 sm:mt-0 lg:px-8 xl:-mt-8'>
        <div className='mx-auto max-w-2xl lg:mx-0 lg:max-w-none'>
          <h2 className='text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl'>
            Quiénes somos
          </h2>
          <div className='mt-6 flex flex-col gap-x-8 gap-y-20 lg:flex-row'>
            <div className='lg:w-full lg:max-w-2xl lg:flex-auto'>
              <p className='text-xl leading-8 text-gray-600'>
                Hoy en día, Mundo de Hules cuenta con diez sucursales y una matriz, es una empresa
                sólida, comprometida con México, con sus empleados pero sobre todo con sus clientes,
                quienes son el elemento más importante para nuestra empresa.
              </p>
              {/* <div className='mt-10 max-w-xl text-base leading-7 text-gray-700'>
                <p>
                  En Mundo de Hules, nos especializamos en la venta y distribución de productos de
                  hule y hule metal, para el ramo automotriz, equipo pesado e industrial, tales
                  como:
                </p>
                <p className='mt-10'>
                  Et vitae blandit facilisi magna lacus commodo. Vitae sapien duis odio id et. Id
                  blandit molestie auctor fermentum dignissim. Lacus diam tincidunt ac cursus in
                  vel. Mauris varius vulputate et ultrices hac adipiscing egestas. Iaculis convallis
                  ac tempor et ut. Ac lorem vel integer orci.
                </p>
              </div> */}
            </div>
            <div className='lg:flex lg:flex-auto lg:justify-center'>
              <dl className='w-64 space-y-8 xl:w-80'>
                {stats.map((stat) => (
                  <div key={stat.label} className='flex flex-col-reverse gap-y-4'>
                    <dt className='text-base leading-7 text-gray-600'>{stat.label}</dt>
                    <dd className='text-5xl font-semibold tracking-tight text-gray-900'>
                      {stat.value}
                    </dd>
                  </div>
                ))}
              </dl>
            </div>
          </div>
        </div>
      </div>

      {/* Image section */}
      {/* <div className='mt-32 sm:mt-40 xl:mx-auto xl:max-w-7xl xl:px-8'>
        <img src='' alt='' className='aspect-[5/2] w-full object-cover xl:rounded-3xl' />
      </div> */}

      {/* Values section */}
      <div className='mx-auto mt-32 max-w-7xl px-6 sm:mt-40 lg:px-8'>
        <div className='mx-auto max-w-2xl lg:mx-0'>
          <h2 className='text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl'>
            Nuestros productos
          </h2>
          <p className='mt-6 text-lg leading-8 text-gray-600'>
            En Mundo de Hules, nos especializamos en la venta y distribución de productos de hule y
            hule metal, para el ramo automotriz, equipo pesado e industrial, tales como:
          </p>
        </div>
        <dl className='mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 text-base leading-7 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:grid-cols-3'>
          {values.map((value) => (
            <div key={value.name}>
              <dt className='font-semibold text-gray-900'>{value.name}</dt>
              <dd className='mt-1 text-gray-600'>{value.description}</dd>
            </div>
          ))}
        </dl>
      </div>
    </main>
  )
}
