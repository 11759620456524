import NACOZARI from '../img/nacozari.jpg'
import MANDARINA from '../img/mandarina.jpg'
import C_DEL_SUR from '../img/c.del-sur.jpg'
import AVILA_CAMACHO from '../img/avilacamacho.jpg'
import COPERNICO from '../img/copernico.jpg'
import EL_VERDE from '../img/elverde.jpg'
import JAVIER_MINA from '../img/javiermina.jpg'
import JUAN_PABLO_II from '../img/juan_pabloII.jpg'
import PATRIA from '../img/patria.jpg'
import EJERCITO from '../img/ejercito.jpg'
import LAZARO from '../img/lazaro.jpg'
const sucursales = [
  {
    sucursal: 'NACOZARI (MATRIZ)',
    ciudad: 'Guadalajara',
    dire: {
      calle: 'Av. Héroe de Nacozari #225',
      col: 'Col. Ferrocarril',
      cp: 'C.P. 44440',
    },
    tele: ['(33) 3812-3105', '(33) 3335-1263', '(33) 3812-2218', '(33) 3334-6138'],
    mail: 'nacozari@mundodehules.com',
    img: NACOZARI,
    map: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3733.5306463542015!2d-103.35452458469733!3d20.64797948620578!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8428b20ea8703161%3A0x2e62160308a74336!2sMUNDO+DE+HULES!5e0!3m2!1ses!2smx!4v1500594464840',
  },
  {
    sucursal: 'ABASTOS',
    ciudad: 'Guadalajara',
    dire: {
      calle: 'Av. Mandarina # 1690',
      col: 'Col. Bosques de la Victoria',
      cp: 'C.P. 44540',
    },
    tele: ['(33) 3162-1181'],
    mail: 'mandarina@mundodehules.com',
    img: MANDARINA,
    map: 'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1866.7741185462226!2d-103.3834102!3d20.6472631!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x8e07aacdcb1c8c40!2sMundo+de+Hules!5e0!3m2!1ses!2s!4v1469063377631',
  },
  {
    sucursal: 'CRUZ DEL SUR',
    ciudad: 'Guadalajara',
    dire: {
      calle: 'Av. Cruz del Sur # 4000',
      col: 'Col. Loma Bonita Ejidal',
      cp: 'C.P. 45085',
    },
    tele: ['(33) 1374-0618'],
    mail: 'cruzdelsur@mundodehules.com',
    img: C_DEL_SUR,
    map: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3733.910133835906!2d-103.40458048475234!3d20.632519506562037!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8428adb48150d057%3A0x91c5d37692ad0794!2sAv%20Cruz%20del%20Sur%204000%2C%20Loma%20Bonita%20Ejidal%2C%2045085%20Zapopan%2C%20Jal.!5e0!3m2!1ses-419!2smx!4v1581452704237!5m2!1ses-419!2smx',
  },
  {
    sucursal: 'AVILA CAMACHO',
    ciudad: 'Guadalajara',
    dire: {
      calle: 'Av Avila Camacho #1245',
      col: 'Col. La Normal',
      cp: 'C.P. 44270',
    },
    tele: ['(33) 3330-6905'],
    mail: 'avilacamacho@mundodehules.com',
    img: AVILA_CAMACHO,
    map: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d879.6884363392464!2d-103.35415056402134!3d20.69813033256947!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8428b1db74fd629d%3A0x4642f82c0d7c0d8d!2sMundo+de+Hules+Avila+Camacho!5e0!3m2!1ses!2smx!4v1500594756663',
  },
  {
    sucursal: 'COPERNICO',
    ciudad: 'Zapopan',
    dire: {
      calle: 'Av. Copérnico # 1668',
      col: 'Residencial Moctezuma Pte.',
      cp: 'C.P. 45059',
    },
    tele: ['(33) 3628-7478'],
    mail: 'copernico@mundodehules.com',
    img: COPERNICO,
    map: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d592.7721403109496!2d-103.43298706749788!3d20.647055358276504!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8428ac1758c41fab%3A0xa118766fbc1e3794!2sMundo+de+Hules+Cop%C3%A9rnico!5e0!3m2!1ses!2smx!4v1500594829496',
  },
  {
    sucursal: 'EL VERDE',
    ciudad: 'El Salto',
    dire: {
      calle: 'Carretera a El Verde #980 A',
      col: 'Col. Ermita',
      cp: 'C.P. 45693',
    },
    tele: ['(33) 1524-6347'],
    mail: 'elverde@mundodehules.com',
    img: EL_VERDE,
    map: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3735.4045673709966!2d-103.3106569846984!3d20.571529586247838!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x842f4cd44350e303%3A0xbfb7ca7f49cc8cd9!2sMundo+de+Hules+El+Verde!5e0!3m2!1ses!2smx!4v1500594876780',
  },
  {
    sucursal: 'MINA',
    ciudad: 'Guadalajara',
    dire: {
      calle: 'Av. Javier mina #1875 SL',
      col: '',
      cp: 'C.P. 44730',
    },
    tele: ['(33) 3655-0287'],
    mail: 'mina@mundodehules.com',
    img: JAVIER_MINA,
    map: 'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14931.97146749894!2d-103.3090114!3d20.6698692!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8428b3d68235ca5d%3A0x206411582bc237e9!2sMundo+De+Hules%2C+S.A.+De+C.V.!5e0!3m2!1ses!2s!4v1469062025364',
  },
  {
    sucursal: 'OBRERO',
    ciudad: 'Guadalajara',
    dire: {
      calle: 'Calz Juan Pablo II #1650-A SL',
      col: '',
      cp: 'C.P. 44350',
    },
    tele: ['(33) 3651-3085'],
    mail: 'obrero@mundodehules.com',
    img: JUAN_PABLO_II,
    map: 'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1866.22842540561!2d-103.3112099!3d20.6916651!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x12be5f29bb4c92b1!2sMundo+de+Hules!5e0!3m2!1ses!2s!4v1469062580730',
  },
  {
    sucursal: 'PATRIA',
    ciudad: 'Zapopan',
    dire: {
      calle: 'Av Patria #2738-B',
      col: 'Col. El colli',
      cp: 'C.P. 45059',
    },
    tele: ['(33) 3125-3015'],
    mail: 'patria@mundodehules.com',
    img: PATRIA,
    map: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d933.4227203854346!2d-103.42074097081246!3d20.641452999138085!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8428ac3971e2dde5%3A0x1dee1b29de5c5bdb!2sMUNDO+DE+HULES!5e0!3m2!1ses!2s!4v1469063428830',
  },
  {
    sucursal: 'Lázaro Cárdenas',
    ciudad: 'Guadalajara',
    dire: {
      calle: 'Calz. Lázaro Cárdenas 1980',
      col: 'Del Fresno',
      cp: 'C.P. 44909',
    },
    tele: ['(33) 1975-5609'],
    mail: 'mhlazarocardenas@gmail.com',
    img: LAZARO,
    map: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3733.3970315276238!2d-103.37190129999999!3d20.6534202!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8428ad3dcbfa9b77%3A0xff15a11097e02f37!2sMultiopciones%20del%20Pac%C3%ADfico!5e0!3m2!1sen!2smx!4v1721704031840!5m2!1sen!2smx" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade',
  },
  {
    sucursal: 'Ejército',
    ciudad: 'Guadalajara',
    dire: {
      calle: 'Calzada del Ejército 1267',
      col: 'Quinta Velarde',
      cp: 'C.P. 44430',
    },
    tele: ['(33) 4523-7494'],
    mail: 'ejercitomh@gmail.com',
    img: EJERCITO,
    map: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3733.28979535348!2d-103.33515899999999!3d20.657785800000003!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8428b22286bf683b%3A0x1132aacdbf9ae769!2sCalz.%20del%20Ej%C3%A9rcito%201267%2C%20Quinta%20Velarde%2C%2044430%20Guadalajara%2C%20Jal.!5e0!3m2!1sen!2smx!4v1742517112607!5m2!1sen!2smx',
  },
]
export default function Contact() {
  return (
    <div className='bg-white py-24 sm:py-32'>
      <div className='mx-auto max-w-7xl px-6 lg:px-8'>
        <div className='mx-auto max-w-2xl lg:mx-0'>
          <h2 className='text-3xl font-bold tracking-tight text-gray-900'>SUCURSALES</h2>
          <p className='mt-6 text-lg leading-8 text-gray-600'>Todas nuestras sucursales.</p>
        </div>
        <div className='mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-8 text-base leading-7 sm:grid-cols-2 sm:gap-y-16 lg:mx-0 lg:max-w-none lg:grid-cols-4'>
          {sucursales.map((s) => (
            <div className='flex flex-col justify-between'>
              <h3 className='border-l border-sky-600 pl-6 font-semibold text-gray-900'>
                {s.sucursal}
              </h3>
              <h4 className='border-l border-sky-600 pl-6 font-semibold text-gray-900'>
                {s.ciudad}
              </h4>
              <h5 className='text-base font-semibold leading-7 text-gray-900'>Dirección</h5>
              <address className='border-l border-gray-200 pl-6 pt-2 not-italic text-gray-600'>
                <p className='-mb-1'>{s.dire.calle}</p>
                <p className='-mb-1'>{s.dire.col}</p>
                <p>{s.dire.cp}</p>
              </address>
              <h5 className='text-base font-semibold leading-7 text-gray-900'>Contacto</h5>
              <div className='border-l border-gray-200 pl-6 mb-2 pt-2 not-italic text-gray-600'>
                <div>
                  <dt className='sr-only'>Email</dt>
                  <dd>
                    <a className='font-semibold text-sky-600' href={`mailto:${s.mail}`}>
                      {s.mail}
                    </a>
                  </dd>
                </div>
                <div className='mt-1'>
                  <dt className='sr-only'>Phone number</dt>
                  <div className='grid md:grid-cols-2'>
                    {s.tele.map((t) => (
                      <dd className='text-sm w-1/2 sm:w-full'>{t}</dd>
                    ))}
                  </div>
                </div>
              </div>
              <div className='relative h-64 w-full overflow-hidden rounded-lg bg-white sm:aspect-h-1 sm:aspect-w-2 lg:aspect-h-1 lg:aspect-w-1 group-hover:opacity-75 sm:h-44'>
                <img
                  src={s.img}
                  alt='Nacozari Image'
                  className='h-full w-full object-cover object-center'
                />
              </div>
              <div className='pt-4'>
                <iframe
                  src={s.map}
                  width='300'
                  height='200'
                  frameBorder='0'
                  style={{ border: 0 }}
                  allowFullScreen
                ></iframe>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
