import { useContext } from 'react'
import { Store } from '../Store'
import { useNavigate } from 'react-router-dom'
import type { Address, Product, User } from 'types/api'
import { Helmet } from 'react-helmet-async'
import { CLIENT_ID } from 'src/config/config'
import AdaAPI from 'adaflex-api'
import { BuyForm } from 'lib'

type InitialState = {
  userInfo: User
  cart: {
    shippingAddress: Address
    cartItems: Product[]
    itemsPrice: number
    shippingPrice: number
    taxPrice: number
    totalPrice: number
  }
  pf: any
  adaAPI: AdaAPI
}

export default function BuyOrder() {
  const {
    state,
  }: {
    state: InitialState
  } = useContext(Store)

  const navigate = useNavigate()
  console.log(CLIENT_ID)
  return (
    <>
      <Helmet>
        <title>Compra</title>
      </Helmet>
      <BuyForm
        stateData={state}
        CLIENT_ID={CLIENT_ID as string}
        BASE_URL={process.env.REACT_APP_BASE_URL as string}
        navigate={navigate}
      />
    </>
  )
}
