import { useEffect } from 'react'

import { primeraMayuscula, reemplazarAsteriscos } from 'src/utils/object'
import { Link, useNavigate, useParams } from 'react-router-dom'
import LoadingBox from 'src/components/LoadingBox'
import MessageBox from 'src/components/MessageBox'
import { useQuery } from '@tanstack/react-query'
import { getProductsTree } from 'src/api/products'

export default function FilterMarc() {
  const navigate = useNavigate()
  const params = useParams()
  const { agrupName } = params
  const searchParams = new URLSearchParams(location.search)
  const productPage = Number(searchParams.get('pagina')) || 1
  const productoQty = 1
  const { data, isLoading, error, isFetching, ...otro } = useQuery(
    ['products'],
    () => getProductsTree(agrupName, productoQty, productPage),
    {
      retry: 3,
      retryDelay: 3000,
      refetchOnWindowFocus: false,
    },
  )
  const breadcrumbs = [
    { id: 1, name: 'Mundo de Hules', href: '/' },
    { id: 2, name: 'Inicio', href: '/hules' },
    { id: 3, name: data?.groups?.[0]?.agrupacion, href: `/hules/${agrupName}` },
  ]
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const productos = data?.brands?.map((p) => ({
    marca: p.marca,
    imagen: `https://mundohules.adaflex.mx:4430/imagenes/marcas/${p.marca}.png`,
  }))

  const group = data?.lines?.map((g) => {
    const obj = {
      nombre: g.linea,
      cantidad: g.cantidad,
      href: `/hules/categoria/${g.linea}`,
    }
    return obj
  })

  const brand = data?.brands?.map((g) => {
    const obj = {
      nombre: g.marca,
      cantidad: g.cantidad,
      href: `/hules/marcas/${g.marca}`,
    }
    return obj
  })

  if (isLoading || isFetching) {
    return (
      <span className='mt-3 flex justify-center items-center container mb-3 h-full'>
        <LoadingBox />
      </span>
    )
  }
  if (error instanceof Error) {
    return (
      <span className='mt-3 flex justify-center container mb-3'>
        <MessageBox variant='danger'>{error.message}</MessageBox>
      </span>
    )
  }

  if (data?.status === 'Error') {
    console.error(data?.status + ': ' + data.message)
    return (
      <span className='mt-3 flex justify-center container mb-3'>
        {/* <MessageBox variant='danger'>{data.message}</MessageBox> */}
        <MessageBox variant='danger'>Error de conexión. Recargue la página.</MessageBox>
      </span>
    )
  }

  return (
    <>
      <div className='bg-white'>
        <div>
          <div className='border-b border-gray-200'>
            <nav aria-label='Breadcrumb' className='mx-auto max-w-7xl px-4 sm:px-6 lg:px-8'>
              <ol role='list' className='flex flex-wrap items-center space-x-4 py-4'>
                {breadcrumbs.map((breadcrumb) => (
                  <li key={breadcrumb.id}>
                    <div className='flex items-center'>
                      <Link to={breadcrumb.href} className='mr-4 text-sm font-medium text-gray-900'>
                        {primeraMayuscula(breadcrumb.name || '')}
                      </Link>
                      <svg
                        viewBox='0 0 6 20'
                        aria-hidden='true'
                        className='h-5 w-auto text-gray-300'
                      >
                        <path
                          d='M4.878 4.34H3.551L.27 16.532h1.327l3.281-12.19z'
                          fill='currentColor'
                        />
                      </svg>
                    </div>
                  </li>
                ))}
              </ol>
            </nav>
          </div>
          <section className='mt-4 -mb-10 ml-28'>
            <button
              type='button'
              onClick={() => navigate(-1)}
              className='hidden lg:block rounded-md bg-sky-500 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-sky-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-500'
            >
              Regresar
            </button>
          </section>
          <main className='mx-auto max-w-2xl px-4 md:max-w-5xl lg:max-w-7xl lg:px-8'>
            <div className='flex items-baseline justify-between border-b border-gray-200 pb-4 pt-12'>
              <h1 className='text-4xl font-bold tracking-tight text-gray-900'>
                Marcas {primeraMayuscula(data?.groups?.[0]?.agrupacion ?? '')}
              </h1>
            </div>

            <div className='pb-24 pt-12'>
              <section aria-labelledby='product-heading' className='mt-6 lg:mt-0'>
                <h2 id='product-heading' className='sr-only'>
                  Productos
                </h2>

                <div className='grid grid-cols-2 gap-x-4 gap-y-4 sm:grid-cols-2 sm:gap-x-6 sm:gap-y-10 md:grid-cols-3 lg:gap-x-8 lg:grid-cols-4 xl:grid-cols-4'>
                  {productos?.map((category) => (
                    <a
                      key={category.marca}
                      href={`/hules/${agrupName}/${category.marca}`}
                      className='group relative flex flex-col overflow-hidden rounded-lg border border-gray-200 bg-white transition ease-in-out hover:-translate-y-1 hover:scale-105 duration-150 h-80 p-6 hover:opacity-75 xl:w-auto'
                    >
                      {/* <span aria-hidden='true' className='absolute inset-0'> */}
                      {category.imagen && (
                        <img
                          src={category.imagen}
                          alt={category.marca}
                          onError={(e: React.SyntheticEvent<HTMLImageElement, Event>) => {
                            ;(e.target as HTMLImageElement).src =
                              'https://mundohules.adaflex.mx:4430/imagenes/logo.png'
                            ;(e.target as HTMLImageElement).className =
                              'w-full px-16 pt-16 object-contain object-center'
                          }}
                          className='h-full w-full object-contain object-center'
                        />
                      )}
                      {/* </span> */}
                      <span
                        aria-hidden='true'
                        className='absolute inset-x-0 bottom-0 h-2/3 bg-gradient-to-t from-gray-800 opacity-50'
                      />
                      <span className='relative mt-auto text-base md:text-lg text-center lg:text-xl font-bold text-white'>
                        {reemplazarAsteriscos(category.marca)}
                      </span>
                    </a>
                  ))}
                </div>
              </section>
            </div>
            <section className='pb-5 ml-44 flex justify-end'>
              <button
                type='button'
                onClick={() => navigate(-1)}
                className='rounded-md bg-sky-500 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-sky-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-500'
              >
                Regresar
              </button>
            </section>
          </main>
        </div>
      </div>
    </>
  )
}
